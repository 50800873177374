<template>
  <div>
    <div class="agreement-underline-header">
      <div>
        甲方：<span>{{ doctorInfo.agreement_company_name }}</span>
      </div>
    </div>
    <div class="agreement-underline-header-row">
      <div class="agreement-underline-header">
        <div>
          乙方：<span>{{ doctorInfo.name }}</span>
        </div>
      </div>
      <div class="agreement-underline-header">
        <div>
          联系方式：<span>{{ doctorInfo.phone }}</span>
        </div>
      </div>
    </div>
    <div class="agreement-underline-header">
      <div>
        身份证号码：<span>{{ doctorInfo.id_card_number }}</span>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
export default {
  name: "common-header",
  props: {
    doctorInfo: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  created() {
    console.log(this.doctorInfo);
  },
};
</script>
<style>
.agreement-underline-header {
  flex: 1 0 0;
  border-bottom: 1px solid #000;
  line-height: 13px;
  display: flex;
  align-items: flex-end;
}
.agreement-underline-header {
  margin-top: 10px;
}
.agreement-underline-header-row {
  display: flex;
}
</style>