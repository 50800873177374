<template>
  <div class="commonAgreement-container">
    <div>
      <div>
        <div class="agreement-h1">劳务协议</div>
        <CommonHeader :doctorInfo="doctorInfo"></CommonHeader>
        <div class="agreement-abstract">
          为了促进医学健康领域学术交流，推动健康公益事业发展，中关村精准医学基金会主办的
          “
          <span class="agreement-underline-span">谈骨论经</span>
          ”项目,现聘请专家参与解读和讨论，将资助专家参加会议。甲方聘请乙方提供宣讲服务;为明确双方义务及权利，经双方协商达成以下一致协议:
        </div>

        <div class="agreement-h3">第一条：</div>
        <p class="agreement-content-row">
          期限为自协议签署之日起至项目结束终止，
        </p>
      </div>
      <div>
        <div class="agreement-h3">第二条：</div>
        <p class="agreement-content-row">
          乙方提供的劳务内容:公益健康知识宣讲。
        </p>
      </div>
      <!-- <p class="agreement-content-row">账户信息：</p>
        <div class="agreement-underline-div">
          姓名：<span>{{ doctorInfo.name }}</span>
        </div>
        <div class="agreement-underline-div">
          联系方式：<span>{{ doctorInfo.phone }}</span>
        </div>
        <div class="agreement-underline-div">
          身份证号码：<span>{{ doctorInfo.id_card_number }}</span>
        </div>
        <div class="agreement-underline-div">
          开户银行（包括支行信息）：<span
            >{{ doctorInfo.bank_name }}{{ doctorInfo.bank_name_point }}</span
          >
        </div>
        <div class="agreement-underline-div">
          账号：<span>{{ doctorInfo.bank_number }}</span>
        </div> -->

      <div>
        <div class="agreement-h3">第三条： 劳务费用支付及方式</div>
        <p class="agreement-content-row">
          本合同采用按次结算，乙方提供本人身份证照片与银行卡信息，甲方依法为乙方代扣代缴个人所得税，支付税后劳务费
          <span class="agreement-underline-div">{{ doctorInfo.money }}</span
          >元整，支付方式经双方协商结算。
        </p>
      </div>

      <div>
        <div class="agreement-h3">第四条 双方的基本权利与义务</div>
        <p class="agreement-content-row">
          1、如乙方严重违反合同约定及法律规定，甲方有权终止协议。
        </p>
        <p class="agreement-content-row">
          2、乙方由于个人原因所发生的疾病以及伤残等意外事故，乙方自行负责。
        </p>
        <p class="agreement-content-row">
          3、任何一方单方面解除本协议的，需以书面通知告知另一方，经双方协商终止本协议。
        </p>
        <p class="agreement-content-row">
          4、双方应严格保守在合作过程中所了解的商业及技术机密，否则应对因此造成的损失承担赔
        </p>
        <p class="agreement-content-row">
          5、本协议解除或终止时，乙方应向甲方指定的人员交接工作;并立即停止以甲方名义从事一
          切活动。
        </p>
        <p class="agreement-content-row">
          6、乙方应按照甲方要求提供劳务服务，不得弄虚作假。
        </p>
        <p class="agreement-content-row">
          7、乙方保证具有完成劳务服务的专业知识与经验，不得提供虚假履历与身份信息。
        </p>
      </div>
      <div>
        <div class="agreement-h3">第五条 违约责任</div>
        <p class="agreement-content-row">
          1、乙方未按照合同约定提供劳务，甲方有权减少支付劳务报酬。
        </p>
        <p class="agreement-content-row">
          2、乙方不履行劳务的，甲方有权不予支付劳务报酬且乙方应当承担所有劳务报酬费用
          20%的违约金。
        </p>
      </div>
      <div>
        <div class="agreement-h3">第六条 本协议解除终止情形:</div>
        <p class="agreement-content-row">1、双方就解除本协议协商一致的;</p>
        <p class="agreement-content-row">
          2、乙方专业能力无法完成合同约定内容的。
        </p>
        <p class="agreement-content-row">
          3、因不可抗力或其他原因致使本协议无法继续履行的。
        </p>
      </div>
      <div>
        <div class="agreement-h3">第七条：</div>
        <p class="agreement-content-row">
          本协议如有未尽事宜，双方另行签订补充协议，补充协议与本协议具有同等法律效力。
        </p>
      </div>
      <div>
        <div class="agreement-h3">第八条：</div>
        <p class="agreement-content-row">
          双方因本协议发生争议首先应友好协商解决，协商不成均可向甲方所在地人民法院诉讼解决。
        </p>
      </div>
      <div>
        <div class="agreement-h3">第九条：</div>
        <p class="agreement-content-row">
          本协议一式两份，甲、乙双方各执一份，经甲乙双方签字盖章之日起生效。
        </p>
      </div>
    </div>
    <div class="agreement-sign-info">
      <div class="agreemtn-sign-them">
        <div class="agreement-sign-name">
          <span>甲方：</span>{{ doctorInfo.company }}
        </div>
        <div class="agreement-sign-date">
          日期：{{ $tools.getDate(data.sign_time, "yyyy年MM月dd日") }}
        </div>
      </div>
      <div class="agreemtn-sign-owner">
        <div class="agreement-sign-name">
          <span ref="signDataOne">乙方：</span>
          <img
            :src="signInfo.data[0]"
            @click="doSignName(0)"
            v-if="signInfo.data[0]"
            alt=""
          />
          <CustomButton
            class="agreement-sign-button"
            size="small"
            @click="doSignName(0)"
            v-else-if="!signInfo.data[0] && isCanSign"
          >
            <div>
              <span>签名</span>
            </div>
          </CustomButton>
        </div>
        <div class="agreement-sign-date">
          日期：{{ $tools.getDate(doctorInfo.sign_time, "yyyy年MM月dd日") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import { CircleCheck } from "@element-plus/icons";
import "../../../../assets/icon/iconfont.css";
import CommonHeader from "../header/commonHeader";
export default {
  name: "common-info",
  props: {
    signInfo: {
      type: Object,
      default: {},
    },
    doctorInfo: {
      type: Object,
      default: {},
    },
    isCanSign: {
      type: Boolean,
      default: false,
    },
    meetInfo: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      data: {},
    };
  },
  components: {
    CustomButton,
    CommonHeader,
    CircleCheck,
  },
  methods: {
    verifyData() {
      if (!this.signInfo.data[0]) {
        console.log(this.$refs.signDataOne);
        let height = this.$refs.signDataOne.offsetTop;
        this.$emit("doScroll", height);
        this.$tips.error({ text: "请签署协议后再提交" });
        // this.$tips.error({ text: "请先签署第一个签名" });
        return false;
      }
      // if (!this.signInfo.data[1]) {
      //   let height = this.$refs.signDataTwo.offsetTop;
      //   this.$emit("doScroll", height);
      //   this.$tips.error({ text: "请先签署第二个签名" });
      //   return false;
      // }
      return true;
    },
    doSignName(index) {
      if (!this.isCanSign) return;
      this.$emit("doSignName", index);
    },
  },
};
</script>
<style>
.agreement-sign-button {
  width: 64px !important;
  font-size: 13px;
}
.agreement-td:last-child {
  border-right: 0;
}
.agreement-row:last-child > .agreement-td {
  border-bottom: 0;
}
.agreement-h1-second {
  margin-top: 30px;
}
</style>