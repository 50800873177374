<template>
  <div class="agreement-container">
    <div class="agreement-main" ref="agreementItem">
      <component
        v-if="components"
        :doctorInfo="doctorInfo"
        :meetInfo="meetInfo"
        :signInfo="signInfo"
        :isCanSign="isCanSign"
        @doSignName="doSignName"
        @doScroll="doScroll"
        ref="agreement"
        v-bind:is="components"
      ></component>

      <div
        class="agreemtn-button-submit global-center"
        v-if="!isCanSign && !isSigned"
      >
        <el-button
          round
          class="sign-block-button-item"
          size="small"
          type="primary"
          @click="doShare()"
          >分享链接给专家</el-button
        >
      </div>
      <div class="agreemtn-button-submit global-center" v-else-if="!isSigned">
        <el-button
          round
          class="sign-block-button-item"
          size="small"
          type="primary"
          @click="doCommit()"
          >确认提交</el-button
        >
      </div>
      <autoGraph
        v-if="showSignName"
        @closeSign="showSignName = false"
        @getSignNameURL="receiveSignNameURL"
      ></autoGraph>
      <Share v-if="showShare" @click="showShare = false"></Share>
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import Share from "@/components/unit/Share";
import autoGraph from "@/components/unit/autograph";
import { defineAsyncComponent } from "vue";
import commonInfo from "@/components/page/agreement/components/commonInfo";
import defaultInfo from "@/components/page/agreement/components/defaultInfo";
import weixin from "@/common/weixin";
export default {
  name: "agreement",
  data() {
    return {
      doctorInfo: {},
      meetInfo: {},
      isSigned: false,
      showShare: false,
      signIndex: 0,
      signInfo: {
        data: ["", ""],
      },
      showSignName: false,
      componentsName: "commonInfo",
      isCanSign: false,
    };
  },
  components: {
    autoGraph,
    Share,
    commonInfo,
    defaultInfo,
    CustomButton,
  },
  computed: {
    components() {
      if (!this.componentsName)
        return defineAsyncComponent(() =>
          import(`@/components/page/agreement/components/defaultInfo`)
        );
      return defineAsyncComponent(() =>
        import(`@/components/page/agreement/components/${this.componentsName}`)
      );
    },
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "协议签署";
    // this.isCanSign = !!this.$tools.getStorage("doctor-share") ? false : true
    this.isCanSign = true;

    this.loadData();
  },
  methods: {
    loadData() {
      let data = {
        user_id: this.$route.query.user_id,
        id: this.$route.query.meet_id,
      };
      let url = this.$tools.getURL(this.$urls.agreement.signDoctorInfo, data);
      this.$axios
        .get(url)
        .then((res) => {
          this.doctorInfo = res.data[0];
          this.meetInfo = res.meet_info;
          this.initSignInfo();
          if (
            (this.doctorInfo.professional_qualifications_code == "" &&
              this.doctorInfo.military_hospital == 0) ||
            !this.$tools.isCanSignName(this.doctorInfo)
          ) {
            this.$router.push({
              path: "personalInfo",
              query: {
                ...this.$route.query,
                from: this.$route.path,
              },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    initSignInfo() {
      this.signInfo.data = (this.doctorInfo.sign_name || ",").split(",");
      this.isSigned = !!this.doctorInfo.sign_name;
    },
    doScroll(height) {
      this.$refs.agreementItem.scrollTo(0, height);
    },
    doSignName(index) {
      if (this.isSigned) return;
      this.showSignName = true;
      this.signIndex = index;
    },
    receiveSignNameURL(url) {
      this.signInfo.data[this.signIndex] = url;
      this.showSignName = false;
    },
    verifyData() {
      return this.$refs.agreement.verifyData();
    },
    doShare() {
      this.showShare = true;
      // setTimeout(()=>{
      //   this.showShare = false;
      // },2000)
    },
    doCommit() {
      if (this.loading || !this.verifyData()) return;
      this.loading = true;
      let data = {
        meet_id: this.$route.query.meet_id,
        user_id: this.$route.query.user_id,
        // sign_url: this.signInfo.data.join(","),
        sign_url: this.signInfo.data[0] || this.signInfo.data[1],
      };
      let loading = this.$tips.loading({ text: "协议保存中" });
      this.$axios
        .post(this.$urls.agreement.doctorSign, data, {}, 4000)
        .then(() => {
          this.isSigned = true;
          setTimeout(() => {
            this.loading = false;
            loading.close();
            if (this.$route.query.from) {
              this.$router.push({
                path: this.$route.query.from,
                query: {
                  id: this.$route.query.user_id,
                },
              });
            } else {
              weixin.wx.closeWindow();
            }
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
            this.signInfo.data = [];
            loading.close();
          }, 1000);
        });
    },
  },
  beforeUnmount() {},
};
</script>
<style>
.agreement-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #fff;
  transform: rotate(0);
  overflow: hidden;
  font-size: 13px;
  line-height: 24px;
}
.agreement-main {
  height: 100%;
  overflow: auto;
  position: relative;
}
.agreement-abstract {
  margin: 10px 0;
  text-indent: 26px;
}
.agreement-h1 {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}
.agreement-h3 {
  font-size: 16px;
  margin: 10px 0;
  font-weight: 700;
}
.agreement-underline-span {
  text-decoration: underline;
}
.agreement-underline-div {
  border-bottom: 1px solid #000;
  margin-top: 10px;
}
.agreement-container p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.agreement-content-indent {
  text-indent: 26px;
}

.agreement-split-line {
  width: 100%;
  display: flex;
  align-items: center;
}
.agreement-split-line-block {
  height: 0px;
  flex: 1 0 0;
  border-bottom: #000 1px dashed;
}
.agreemtn-button-submit {
  width: 100%;
  margin-top: 30px;
}
.agreemtn-button-submit .el-button {
  min-width: 50%;
}
.agreement-sign-info {
  display: flex;
}
.agreement-sign-info > div {
  flex: 1 0 0;
}
.agreement-sign-name {
  height: 30px;
  display: flex;
  align-items: center;
}
.agreement-sign-name .customButton-container {
  line-height: 20px !important;
  height: 20px !important;
  width: 80px;
}
.agreement-sign-name span {
  white-space: nowrap;
}
.agreement-sign-name img {
  width: 60px;
  height: auto;
}
.showShare-block {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.agreement-second-title {
  font-size: 14px;
  text-align: center;
}
.agreement-table {
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  margin-bottom: 10px;
}
.agreement-row {
  display: flex;
  min-height: 20px;
}
.agreement-td {
  line-height: 20px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  word-break: break-all;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
}
.agreement-td:first-child {
  flex: 120px 0 0;
}
.agreement-td:last-child {
  flex: 1 0 0;
}
</style>
